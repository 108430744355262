<template>
    <Content :title="'Bem-vindo(a) ' + $store.state.user.name">
        <Interface noBtn :noTitle="true" :noFilter="true" :noHeader="true">
            <div class="d-flex justify-content-center align-items-center">
                <b-card-group deck>
                    <template v-if="chats.length">
                        <b-card v-for="chat in chats" :key="chat.id" @click="goTo('Conversation', chat.id)"
                            img-src="../assets/images/conversation.png" img-alt="conversation" img-top>
                            <b-card-title>
                                <img src="../assets/images/Group 149.png" alt=""> {{ chat.key }}
                            </b-card-title>
                        </b-card>
                    </template>
                    <p v-else>Não há chats disponíveis para o usuário no momento.</p>
                </b-card-group>
            </div>
        </Interface>
    </Content>
</template>

<script>
/* eslint-disable */
import Interface from '@/components/Dashboard/Interface';
import Content from '../components/content/index';
import { toast } from '@/utils';

export default {
    components: {
        Interface,
        Content
    },
    computed: {
        chats() {
            return this.$store.getters.getChats; // Pega os chats do Vuex
        }
    },
    mounted() {
        this.$store.dispatch('fetchChats'); // Busca os chats na API e armazena no Vuex
    },
    methods: {
        goTo(route, chat = null) {
            if (chat) {
                // Enviar apenas os dados necessários para o Vuex
                const chatDataToSend = chat
                this.$store.dispatch('selectChat', chatDataToSend);
                console.log({ 'chat_id': chatDataToSend }); // Log apenas os dados que você enviou
            }

            const isSchoolAdmin = this.$store.state.user.groups.some(group => group.type === "scholl_admin");
            const routes = ['Curso', 'Context'];

            if (!routes.includes(route)) {
                const destination = route === 'Conversation' && isSchoolAdmin ? 'Conversation_Options' : route;
                this.$router.push({ name: destination });
            } else {
                toast(this, 'info', 'Atenção', 'Em desenvolvimento');
            }
        }
    }
}
</script>

<style scoped>
.card {
    cursor: pointer !important;
    transition: 0.5s all ease-in-out;
    padding: 0.7em;
}

.card:hover {
    transform: scale(1.1);
}

.card-img,
.card-img-top {
    height: 300px;
    max-width: max-content;
}

.card-actions {
    gap: 2%;
    margin: auto;
    justify-content: center;
    width: max-content;
    align-content: center;
}

@media screen and (max-width: 920px) {
    .card-title {
        flex-direction: column;
        display: flex;
        align-items: center;
        width: max-content;
    }

    .card-actions {
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
    }

    .card-body {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
